import React from "react";

const Home = () => {
  return (
    <div className="text-center">
      <h1>Make site on react</h1>
      <p>
        Learning react and create new ideas at this place.
      </p>
    </div>
  );
};

export default Home;