import React from "react";

const Bar = () => {
  return (
    <div className="text-center">
      <h1>Chart BAR</h1>
      <p>
        Opening soon...
      </p>
    </div>
  );
};

export default Bar;